<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF RELEASE CLAIMS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md3>
            <v-select
              v-if="!saving"
              v-model="month_of_id"
              class="mx-2"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              :rules="rules.combobox_rule"
              dense
              outlined
            ></v-select>
            <v-select
              v-if="!saving"
              outlined
              class="mx-2"
              v-model="branch_id"
              dense
              label="Branch"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              :rules="rules.combobox_rule"
            ></v-select>
            <v-select
              v-if="!saving"
              v-model="type_of_service"
              class="mx-2"
              :items="['Rendered','UnRendered']"
              label="Status"
              required
              :rules="rules.combobox_rule"
              dense
              outlined
            ></v-select>
            <v-select
              v-if="!saving"
              v-model="rendered_to"
              class="mx-2"
              :items="['All','Member','Dependent']"
              label="Rendered To"
              required
              :rules="rules.combobox_rule"
              dense
              outlined
            ></v-select>
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4 w-full"
                @click="selected_month"
                v-if="!saving"
              >
                Search
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-flex>
          <v-flex xs12 md12>
            <h2>Print |
              <v-icon
                class="mr-2"
                color="success"
                @click="print_data"
                v-if="!printt"
              >
                {{icons.mdiPrinter}}
              </v-icon>
              <v-progress-circular
                :size=25
                :width="5"
                color="success"
                indeterminate
                v-else
              ></v-progress-circular>
              Total Claims: P {{ total_amount }} | Count:
              {{formatCouner(data_items.length)}}
            </h2>
            <v-data-table
              dense
              :headers="headers"
              :items="data_items"
              height="600"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.transaction_month.month_of }}
                  </td>
                  <td>
                    {{ item.type_of_service }}
                  </td>
                  <td>
                    {{ item.maf_no }}
                  </td>
                  <td>
                    {{ item.name_of_cadaver }}
                  </td>
                  <td>
                    {{ item.name_of_member }}
                  </td>
                  <td>
                    {{ item.branch.branch_code }}
                  </td>
                  <td>
                    {{ formatPrice(item.request_data.amount) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiSwapHorizontal, mdiPrinter} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import TransferData from '@/views/view_interface/inventory_monitoring/TransferData'
  import Equipment from '@/views/file_interface/search/components_inventory/Equipment'

  const initialState = () => {
    return {
      saving: false,
      printt: false,

      alert: false,
      saving_data: false,
      alert_message: '',

      headers: [
        {text: 'Month Of', value: 'no', sortable: false},
        {text: 'Rendered To', value: 'no', sortable: false},
        {text: 'Maf #', value: 'transmital_no', sortable: false},
        {text: 'Name of Cadaver', value: 'category.category', sortable: false},
        {text: 'Name of Plan Holder', value: 'branch.branch_code', sortable: false},
        {text: 'Branch', value: 'equipment', sortable: false},
        {text: 'Amount', value: 'equipment_serial_no', sortable: false},
      ],
      data_items: [],
      month_of_items: [],
      branch_items: [],
      type_of_service: '',
      month_of_id: '',
      branch_id: '',
      rendered_to: '',
      total_amount: '0.00',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiPrinter,
          mdiClose, mdiSwapHorizontal
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['employee_id', 'month_of', 'address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing', 'accounting', 'admin', 'amd', 'audit', 'book', 'creative', 'ecut', 'hr', 'it', 'prot', 'trea']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      ...mapActions('list_of_claims', ['list_of_claims']),
      ...mapActions('branch_information', ['list_of_branch_that_have_claims']),
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
            this.month_of_items.splice(0, 0, {
              id: -1,
              month_of: 'All'
            })
          })
          .catch(error => {
            console.log(error)
          })
        this.list_of_branch_that_have_claims()
          .then(response => {
            this.branch_items = response.data
            this.branch_items.splice(0, 0, {
              id: -1,
              branch_code: 'All'
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      formatCouner(value) {
        let val = (value / 1).toFixed(0).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        this.saving = true
        this.alert = false
        this.data_items = []
        this.total_amount='0.00'
        if (this.$refs.form2.validate()) {
          this.list_of_claims({
            month_of_id: this.month_of_id,
            type_of_service: this.type_of_service,
            branch_id: this.branch_id,
            rendered_to: this.rendered_to,
          })
            .then(response => {
              this.saving = false
              this.data_items = response.data
              var tot = 0
              this.data_items.forEach(function (item) {
                tot += parseFloat(item.request_data.amount)
              });
              this.total_amount = this.formatPrice(tot)
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      async print_data() {
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing


        widths = [55, 60, 120, 120, 70, 40]
        payments_array.push([
          {text: 'Month Of', alignment: 'left', style: 'main_info'},
          {text: 'Maf #', alignment: 'left', style: 'main_info'},
          {text: 'Name of Cadaver', alignment: 'left', style: 'main_info'},
          {text: 'Name of Plan Holder', alignment: 'left', style: 'main_info'},
          {text: 'Branch', alignment: 'left', style: 'main_info'},
          {text: 'Amount', alignment: 'left', style: 'main_info'},
        ])


        var tott = this.total_amount
        this.data_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.transaction_month.month_of,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.maf_no,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.name_of_cadaver,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.name_of_member,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.branch.branch_code,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (item.request_data.amount / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2'
              },
              {
                text: tott,
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[this.month_of_items.map(function (x) {
                      return x.id;
                    }).indexOf(this.month_of_id)].month_of,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BRANCH: ',
                  {
                    text: this.branch_items[this.branch_items.map(function (x) {
                      return x.id;
                    }).indexOf(this.branch_id)].branch_code,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'TYPE OF SERVICE: ',
                  {
                    text: this.type_of_service,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'RENDERED TO: ',
                  {
                    text: this.rendered_to,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image: this.position === 'ADMIN' ? this.admin :
                    (this.position === 'TREASURY' ? this.trea :
                      (this.position === 'ACCOUNTING' ? this.accounting :
                        (this.position === 'AMD' ? this.amd :
                          (this.position === 'PROPERTY CUSTODIAN' ? this.prot :
                            (this.position === 'HUMAN RESOURCE' ? this.hr :
                              (this.position === 'BOOKKEEPING' ? this.book :
                                (this.position === 'CMD' ? this.accounting : imgData))))))),
                  width: 60,
                  height: 54,
                  style: 'logo'
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'LIST OF SERVICE',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {

              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left'
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
                {
                  text: ' '
                },
              ],
            };
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      }
    },
  }
</script>
